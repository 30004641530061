import React from "react"
import BELogo from "../../images/belogo200x200.png"
import "./style.scss"

export default function Footer() {
  return (
    <footer className="footer">
      <div className="footer__leftcontent">
        © Future Ready Education Services Pvt. Ltd. {new Date().getFullYear()}{" "}
      </div>
      <div className="footer__rightcontent">
        <div className="footer__rightcontent__text">Powered By </div>
        <a href="https://blackelephantdigital.com">
          <img
            className="footer__rightcontent__image"
            alt="Black Elephant Digital Logo"
            src={BELogo}
            target="__blank"
          />
        </a>
      </div>
    </footer>
  )
}
