import React from "react"
import Layout from "../layout"
import Navbar from "../navbar"
import "./style.scss"
import yearly_sub_img from "../../images/12-month-sub.jpg"
import monthly_sub_img from "../../images/covers/2023/JULY_2023.jpg"
import constants from "../../constants/contants"

function Subscriptions() {
  return (
    <Layout>
      <Navbar />
      <div className="subscriptions">
        <hr className="hr" />
        <h1 className="heading-primary">Subscriptions</h1>

        <div className="subscriptions__cards">
          <div className="subscriptions__card">
            <img
              src={constants.currentCoverLink}
              className="subscriptions__card__img"
              alt="Yearly subscription"
            ></img>
            <div className="subscriptions__card__title">
              Single Month Subscription
            </div>
            <div className="subscriptions__card__rate">₹160/-</div>
            <div className="subscriptions__card__delivery">
              +₹39/- Courier charges.
            </div>
          </div>
          {/* <div className="subscriptions__card">
            <img
              src={yearly_sub_img}
              className="subscriptions__card__img"
              alt="Yearly subscription"
            ></img>
            <div className="subscriptions__card__title">
              12 Months Subscription
            </div>
            <div className="subscriptions__card__rate">₹1920/-</div>
            <div className="subscriptions__card__delivery">
              Zero Courier Charges
            </div>
          </div> */}
        </div>

        <div className="subscriptions__paynow">
          <h2 className="heading-secondary">Pay Now</h2>
          <div className="subscriptions__paynow__details">
            <div>
              <strong>Future Ready Education Services Private Limited</strong>
            </div>
            <div>
              <strong>Swift:</strong> DBSSINBB
            </div>
            <div>
              <strong>Bank name:</strong> DBS Bank India Limited
            </div>
            <div>
              <strong>Bank Address:</strong> DBS Bank India Ltd , unit 4B ,
              Ground floor , DLF Builfing No 10 Tower X , DLF Cyber city , Phase
              II , Gurugram , Haryana - 122002 , India
            </div>
            <div>
              <strong>Account No:</strong> - 8858210000005722
            </div>
            <div>
              <strong>IFSC code:</strong>  DBSS0IN0858
            </div>
          </div>
          <br />
          <center>
            <strong>
              Email details of your bank transfer to{" "}
              <a href="mailto:editor@school-live.com">editor@school-live.com</a>
            </strong>
          </center>
        </div>
      </div>
    </Layout>
  )
}

export default Subscriptions
